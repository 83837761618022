import { load, save } from '../services/storage'

export function getAndStoreUtmParams(): Record<string, string> {
  const params = new URLSearchParams(window.location.search)
  const utmParams: Record<string, string> = {}

  // Extract UTMs from query params
  params.forEach((value: string, key: string) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value
    }
  })

  // If no UTMs found in URL, get them from localStorage
  if (Object.keys(utmParams).length === 0) {
    const storedUtmParams = String(load('utm_data'))
    if (storedUtmParams) {
      return JSON.parse(storedUtmParams) as Record<string, string>
    }
  } else {
    // Store UTMs in localStorage if they were found in the query params
    save('utm_data', JSON.stringify(utmParams))
  }

  return utmParams
}
