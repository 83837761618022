import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ApiErrorTypes } from 'ogram-react'

import { Separator } from '../../../components/app-layout/separator/separator'
import './rating-requests-list.scss'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import Rating from '../../../components/rating/rating'
import locationIcon from '../../../components/assets/location-icon-grey.svg'
import emptyStarIcon from './assets/empty-star.svg'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { TrackedEvent, trackingService } from '../../../services/tracking-service'
import ApplicantDetails from '../../applicants-review/applicant-details/applicant-details'
import { Popup } from '../../../components/popup/popup'

export const RatingRequestsListPage = withErrorBoundary(
  observer(() => {
    const { t } = useTranslation()

    const { getLastRatings, groupedLastRatings, setSPLastJobRating, decreasePendingRatingsCount } = useStore().data

    const [spRatings, setSPRatings] = useState<{ spId: number; rating: number }[]>([])
    const [shownProfile, setShownProfile] = useState<{ id: number; jobId: number; name: string } | null>(null)

    const getRating = (spId: number) => {
      const rating = spRatings.find(r => r.spId === spId)
      return rating ? rating.rating : 0
    }

    const setRating = (spId: number, rating: number, jobId: number) => {
      const newRatings = spRatings.filter(r => r.spId !== spId)
      newRatings.push({ spId, rating })
      setSPRatings(newRatings)

      setSPLastJobRatingRequest({ spId, rating, jobId })
    }

    const { isLoading: lastRatingsLoading } = useQuery(getLastRatings)

    const [, setSPLastJobRatingRequest] = useMutation(
      ({ spId, rating, jobId }: { spId: number; rating: number; jobId: number }) =>
        setSPLastJobRating(spId, rating, null, null, jobId),
      {
        onSuccess: result => {
          toast.success(t('job_rating.rating_submitted'))
          decreasePendingRatingsCount()
          trackingService.track(TrackedEvent.RatingSetFromRatingsList)
        },
        onError: err => {
          toast.error(err.data.errors?.note[0])
        },
        filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
      },
    )

    return (
      <div className="RatingRequestsList">
        <Header title={t('rating_request_list.title')} />
        <Separator.Vertical height={33} />
        {lastRatingsLoading && <LoadingSpinner />}
        {groupedLastRatings.length > 0 ? (
          <>
            <div className="RatingRequestsList__infoPanel">
              <span className="RatingRequestsList__infoPanel__title">{t('job_rating.rate_workers')}</span>
              <span className="RatingRequestsList__infoPanel__subtitle">
                <Trans i18nKey="job_rating.importance_of_rating" components={{ strong: <strong /> }} />
              </span>
            </div>
            <div className="RatingRequestsList__latest">
              {groupedLastRatings.map((group, index) => (
                <div key={index} className="RatingRequestsList__latest__card">
                  <span className="RatingRequestsList__latest__card__title">
                    <strong>{group.designation_name}</strong> ({group.ratings.length})
                  </span>
                  <div className="RatingRequestsList__latest__card__subtitle">
                    <img className="RatingRequestsList__latest__card__subtitle__img" src={locationIcon} />
                    <span className="RatingRequestsList__latest__card__subtitle__text">{group.location_name}</span>
                  </div>
                  {group.ratings.map(rating => (
                    <div key={rating.id} className="RatingRequestsList__latest__card__sp">
                      <div
                        className="RatingRequestsList__latest__card__sp__left"
                        onClick={() => setShownProfile({ id: rating.id, jobId: rating.job_id, name: rating.name })}
                      >
                        <img className="RatingRequestsList__latest__card__sp__img" src={rating.image_url} />
                        <div className="RatingRequestsList__latest__card__sp__info">
                          <span className="RatingRequestsList__latest__card__sp__info__title">{rating.name}</span>
                          <span className="RatingRequestsList__latest__card__sp__info__subtitle">
                            {t('common.ended_at', { date: moment(rating.completed_at, 'YYYY-MM-DD').format('MMM DD') })}
                          </span>
                        </div>
                      </div>
                      <Rating
                        rating={getRating(rating.id)}
                        onRatingChange={(value: number) => {
                          setRating(rating.id, value, rating.job_id)
                        }}
                        editable
                        size={26}
                        emptyIcon={emptyStarIcon}
                        starsGap={8}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {!lastRatingsLoading && (
              <div className="RatingRequestsList__emptyList">{t('rating_request_list.empty_list')}</div>
            )}
          </>
        )}
        {shownProfile && (
          <Popup
            isActive={Boolean(shownProfile)}
            content={
              <ApplicantDetails
                jobId={String(shownProfile.jobId)}
                applicantId={String(shownProfile.id)}
                applicantName={shownProfile.name}
                showHeader={false}
                showControls={false}
              />
            }
            close={() => setShownProfile(null)}
          />
        )}
      </div>
    )
  }),
)
