import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'
import { LeanSetupStatus } from './payment'

export const TAG_ALLOW_NATIONALITY_OPTION = '__allow_nationality_option'

export const ProfileModel = types.model('Profile', {
  id: types.identifierNumber,
  user_id: types.number,
  first_name: types.string,
  last_name: types.maybeNull(types.string),
  email: types.string,
  phone: types.maybeNull(types.string),
  image: types.string,
  total_jobs_count: types.optional(types.number, 0),
  inprogress_jobs_count: types.optional(types.number, 0),
  unpaid_invoices_count: types.optional(types.number, 0),
  rating_request_count: types.optional(types.number, 0),
  lean_setup_completed: types.maybeNull(types.number),
  lean_setup_status: types.maybeNull(types.number),
  allow_marketing_emails: types.number,
  roles: types.array(types.number),
  tags: types.array(types.string),
})

export const profileActions = (self: DataStore) => ({
  getProfile: flow(function* () {
    self.profile = (yield self.request('post', 'client/profile/summary')) as Profile

    return self.profile
  }),

  updateProfile: flow(function* (data: Partial<ProfileFormType>) {
    yield self.request('post', 'client/profile/basic/update', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    if (self.account) {
      self.account.is_basic_info_complete = true
      self.account.name = `${data.first_name} ${data.last_name}`
    }
  }),

  changePassword: flow(function* (params: { password: string; old_password: string }) {
    yield self.request('post', 'client/password/change', params)
  }),

  acceptSLA: flow(function* () {
    yield self.request('post', 'client/profile/sla/accept')
  }),

  switchProfile: flow(function* (id: number) {
    self.account = yield self.request('post', `client/profile/switch/${id}`)
  }),

  setLeanSetupCompleted: function (value: number) {
    if (self.profile) {
      self.profile.lean_setup_status = LeanSetupStatus.LEAN_SETUP_STATUS_ACTIVE
    }
  },
})

export type Profile = typeof ProfileModel.Type
export type ProfileSnapshot = typeof ProfileModel.SnapshotType
export type ProfileFormType = Omit<Profile, 'image'> & { image: File | null | undefined; image_url: string | null }
