import { types, cast, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const LanguagesModel = types
  .model('Languages', {
    use_languages: types.optional(types.boolean, false),
    list: types.maybeNull(types.array(types.number)),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },

    has(id: number) {
      return Boolean(self.list?.includes(id))
    },
  }))
  .actions(self => ({
    clear() {
      self.list?.clear()
    },
  }))
  .actions(self => ({
    add(id: number) {
      if (self.list) {
        self.list.push(id)
      } else {
        self.list = cast([id])
      }
      self.error = null
    },

    remove(id: number) {
      self.list = cast(self.list?.filter(item => item !== id))
    },

    set(languages: number[]) {
      self.list = cast(languages)
      self.error = null
    },

    useLanguages(languagesRequired: boolean) {
      self.use_languages = languagesRequired
      if (!languagesRequired) {
        self.clear()
      }
      self.error = null
    },

    validate() {
      if (self.use_languages && (self.list === null || self.list.length === 0)) {
        self.error = t('job_draft_details.languages_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createLanguagesMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.languages) {
      return abort(targetInstance.languages.validate())
    }
    return next(call)
  })
}
