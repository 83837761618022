import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { useStore, useQuery } from '../../../../../utils/mst-hooks'
import { Checkbox } from '../../../../checkbox/checkbox'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { SearchInput } from '../../../../search-input/search-input'
import { JobDraftFieldError } from '../error/error'
import './nationalities.scss'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { NationalityInstance } from '../../../../../models/nationality'
import { Toggle } from '../../../../toggle/toggle'

export const JobDraftNationalities = observer(({ job }: { job: DraftJobInstance }) => {
  const { nationalitiesList, allNationalities, getNationalities } = useStore().data
  const [originalNationalities, setOriginalNationalities] = useState<NationalityInstance[]>([])
  const [filteredNationalities, setFilteredNationalities] = useState<NationalityInstance[]>([])
  const [calculatingList, setCalculatingList] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const { isLoading: nationalitiesLoading } = useQuery(getNationalities)

  const filterNationalities = useDebouncedCallback((value: string) => {
    setFilteredNationalities(
      originalNationalities.filter(item => item.name.toLowerCase().includes(value.toLowerCase())),
    )
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  useEffect(() => {
    if (allNationalities.length > 0) {
      setOriginalNationalities(nationalitiesList(!job.existing_job_id))
      setFilteredNationalities(nationalitiesList(!job.existing_job_id))
    }
  }, [allNationalities, allNationalities.length])

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterNationalities(value)
  }

  return (
    <div className="JobDraftNationalities">
      <span className="Popup__title">{t('job_draft_details.nationalities_title')}</span>
      <span className="Popup__subtitle" style={{ marginBottom: '20px' }}>
        {t('job_draft_details.nationalities_subtitle')}
      </span>
      {nationalitiesLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '56px',
              width: '100%',
              paddingRight: '10px',
            }}
          >
            <span style={{ marginRight: '10px' }} className="JobField__label">
              <strong>{t('job_draft_details.nationalities_option_any')}</strong>
            </span>
            <Toggle
              active={Boolean(job.nationalities.allow_any_nationality)}
              onToggle={() => {
                if (!job.nationalities.allow_any_nationality) {
                  job.nationalities.setNationalities([])
                  job.nationalities.setEthnicities([])
                }
                job.nationalities.setAllowAnyNationality(!job.nationalities.allow_any_nationality)
              }}
            />
          </div>
          <SearchInput
            value={searchValue}
            handleValueChange={handleSearchChange}
            className="JobDraftNationalities__searchContainer"
          />
          <Separator.Vertical height={18} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div className={`JobDraftNationalities__list ${job.nationalities.hasError ? 'error' : ''}`}>
              {filteredNationalities.map((nationality, idx) => {
                const selected = nationality.is_ethnicity
                  ? job.nationalities.hasEthnicity(nationality.id)
                  : job.nationalities.hasNationality(nationality.id)
                return (
                  <div
                    onClick={() => {
                      if (selected) {
                        nationality.is_ethnicity
                          ? job.nationalities.removeEthnicity(nationality.id)
                          : job.nationalities.removeNationality(nationality.id)
                      } else {
                        nationality.is_ethnicity
                          ? job.nationalities.addEthnicity(nationality.id)
                          : job.nationalities.addNationality(nationality.id)
                      }

                      job.nationalities.setAllowAnyNationality(
                        job.nationalities.nationalities_list?.length === 0 &&
                          job.nationalities.ethnicities_list?.length === 0,
                      )
                    }}
                    key={idx}
                    className="JobDraftNationalities__list__item"
                  >
                    <span className="JobDraftNationalities__list__item__label">{nationality.name}</span>
                    <Checkbox isActive={selected} type="square" />
                  </div>
                )
              })}
            </div>
          )}
          <JobDraftFieldError error={job.nationalities.error} />
          <Separator.Vertical height={20} />
        </>
      )}
    </div>
  )
})
