import { types, flow, SnapshotIn, SnapshotOut, Instance } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const NationalityModel = types.model('Nationality', {
  id: types.identifierNumber,
  name: types.string,
  ethnicity_id: types.maybeNull(types.number),
  ethnicity_name: types.maybeNull(types.string),
  is_ethnicity: types.boolean,
})

export const nationalityActions = (self: DataStore) => ({
  getNationalities: flow(function* () {
    const nationalities = (yield self.request('get', 'common/nationalities?with_ethnicity=1')) as NationalitySnapshot[]
    nationalities.forEach(nationality => {
      self.nationalities.set(String(nationality.id), {
        ...nationality,
        is_ethnicity: false,
      })
    })
  }),
})

export const nationalityViews = (self: DataStore) => ({
  get allNationalities() {
    return Array.from(self.nationalities.values())
  },

  nationalitiesList(onlyEthnicities = true) {
    const nationalities = Array.from(self.nationalities.values())

    const ethnicities: NationalitySnapshot[] = []
    nationalities.forEach(nationality => {
      if (nationality.ethnicity_id && !ethnicities.find(item => item.id === nationality.ethnicity_id)) {
        ethnicities.push({
          id: nationality.ethnicity_id,
          name: String(nationality.ethnicity_name),
          ethnicity_id: null,
          ethnicity_name: null,
          is_ethnicity: true,
        })
      }
    })

    return [...ethnicities, ...(onlyEthnicities ? [] : nationalities)]
  },

  getNationalitiesNames(ids: number[]) {
    return Array.from(self.nationalities.values())
      .filter(item => ids.includes(item.id))
      .map(item => item.name)
  },
})

export type Nationality = SnapshotIn<typeof NationalityModel>
export type NationalitySnapshot = SnapshotOut<typeof NationalityModel>
export type NationalityInstance = Instance<typeof NationalityModel>
