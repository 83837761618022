import { useState } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { SignUpDataType } from '../../../models/auth'
import { AccountSnapshot } from '../../../models/account'
import { useFirebaseToken } from '../../../utils/use-firebase-token'
import { generateDeviceId } from '../../../utils/device-id-generator'
import { trackingService, Conversion } from '../../../services/tracking-service'
import { useRedirect } from '../../../services/router'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { UIProps } from './types'
import { getCurrentMarketConfig } from '../../../services/market-service'
import { t } from '../../../translations'
import { rawCountries } from '../../../components/phone-input/raw-countries'
import { default as getPhoneValidationRules } from '../../../components/phone-input/validation'
import { getAndStoreUtmParams } from '../../../utils/utm-utils'

export function createSignUpContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return observer(({ redirectTo }: { redirectTo?: 'index' | 'setJobCategory' }) => {
    const [isConsentAccepted, setIsConsentAccepted] = useState<boolean>(false)
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null)
    const firebaseToken = useFirebaseToken()

    const { register } = useStore().data

    const [signUpResult, requestSignUp] = useMutation(register, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: (account: AccountSnapshot) => {
        trackingService.identifyUser(Number(account.user_id))
        trackingService.assignUserProperties(account.id, account.roles, account.email)

        redirect(redirectTo ? routes[redirectTo] : routes.index, {})
        toast.success(t('sign_up.thank_you'))
      },
    })

    const redirect = useRedirect()

    const remoteErrors = signUpResult.error?.data.errors
    const submitting = signUpResult.isLoading

    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          password: '',
          companyName: '',
          allow_marketing_emails: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(t('sign_up.first_name_required')),
          lastName: Yup.string().required(t('sign_up.last_name_required')),
          phone: getPhoneValidationRules(
            t('sign_up.phone_required'),
            t('sign_up.phone_invalid'),
            rawCountries[selectedCountry as string],
          ),
          email: Yup.string().email(t('validation_messages.email_invalid')).required(t('sign_up.email_required')),
          isCorporate: Yup.boolean(),
          companyName: getCurrentMarketConfig().registration.allowOnlyCorporate
            ? Yup.string().required(t('sign_up.company_name_required'))
            : Yup.string().when('isCorporate', {
                is: true,
                then: Yup.string().required(t('sign_up.company_name_required')),
              }),
        })}
        enableReinitialize
        onSubmit={values => {
          const submitObject: SignUpDataType = {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            email: values.email,
            password: values.password,
            allow_marketing_emails: Number(values.allow_marketing_emails),
            is_corporate: values.companyName.length > 0 ? 1 : 0,
            device_id: generateDeviceId(),
            firebase_token: firebaseToken,
            utm: getAndStoreUtmParams(),
          }
          if (values.companyName.length > 0) {
            submitObject.company_name = values.companyName
          }
          requestSignUp(submitObject)
        }}
        validateOnChange={false}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => {
          return (
            <UIComponent
              submitting={submitting}
              firstName={values.firstName}
              firstNameErrors={errors.firstName ?? remoteErrors?.first_name}
              lastName={values.lastName}
              lastNameErrors={errors.lastName ?? remoteErrors?.last_name}
              email={values.email}
              emailErrors={errors.email ?? remoteErrors?.email}
              phone={values.phone}
              phoneErrors={errors.phone ?? remoteErrors?.phone}
              password={values.password}
              passwordErrors={errors.password ?? remoteErrors?.password}
              companyName={values.companyName}
              companyNameErrors={errors.companyName ?? remoteErrors?.company_name}
              allow_marketing_emails={values.allow_marketing_emails}
              allow_marketing_emailsErrors={errors.allow_marketing_emails ?? remoteErrors?.allow_marketing_emails}
              handleFirstNameChange={firstName => {
                setFieldValue('firstName', firstName)
              }}
              handleLastNameChange={lastName => {
                setFieldValue('lastName', lastName)
              }}
              handleEmailNameChange={email => {
                setFieldValue('email', email)
              }}
              handlePhoneNameChange={phone => {
                setFieldValue('phone', phone)
              }}
              handlePasswordChange={password => {
                setFieldValue('password', password)
              }}
              handleCityChange={city => {
                setFieldValue('city', city)
              }}
              handleCompanyNameChange={companyName => {
                setFieldValue('companyName', companyName)
              }}
              handleAllowEmailsToggle={() => {
                setFieldValue('allow_marketing_emails', !values.allow_marketing_emails)
              }}
              handleSubmitButtonPress={() => {
                handleSubmit()
                trackingService.conversion(Conversion.SignUpClicked)
              }}
              handleLoginButtonPress={() => {
                redirect(routes.login, {})
              }}
              isConsentAccepted={isConsentAccepted}
              setIsConsentAccepted={setIsConsentAccepted}
              setSelectedCountry={setSelectedCountry}
            />
          )
        }}
      </Formik>
    )
  })
}
