import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const StatisticsModel = types.model('Statistics', {
  pending_payments_count: types.number,
  shifts_need_confirm_count: types.number,
  orders_need_confirm_count: types.number,
  unfulfilled_jobs_count: types.number,
  pending_ratings_count: types.number,
  referrals_count: types.number,
})

export const statisticsActions = (self: DataStore) => ({
  getStatistics: flow(function* () {
    self.statistics = (yield self.request('get', 'client/home/statistics')) as StatisticsSnapshot
  }),

  decreasePendingRatingsCount: () => {
    if (self.statistics) {
      self.statistics.pending_ratings_count -= 1
    }
  },
})

export type Statistics = Instance<typeof StatisticsModel>
export type StatisticsSnapshot = SnapshotOut<typeof StatisticsModel>
