import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export type RatingRequestDetails = {
  id: number
  title: string
  client_address: string
  start_date: string
  end_date: string
  sp_max_quantity: number
  rating: number | null
  shifts: { start_time: string; end_time: string }[]
  sps: { id: number; full_name: string; image_url: string }[]
}

export const RatingRequestModel = types.model('RatingRequest', {
  id: types.identifierNumber,
  order_id: types.number,
  title: types.string,
  client_address: types.string,
  start_date: types.string,
  end_date: types.string,
  sp_max_quantity: types.number,
})

export const LastRatingModel = types.model('LastRating', {
  id: types.identifierNumber,
  image_url: types.string,
  name: types.string,
  completed_at: types.string,
  location_id: types.number,
  designation_id: types.number,
  location_name: types.string,
  designation_name: types.string,
  job_id: types.number,
})

export const ratingActions = (self: DataStore) => ({
  getRatingRequests: flow(function* (includeActiveJobs: boolean) {
    const ratingRequestArray = (yield self.request(
      'get',
      `client/rating/pending?includeActiveJobs=${Number(includeActiveJobs)}`,
    )) as RatingRequestSnapshot[]
    if (self.ratingRequests.size > 0) {
      self.ratingRequests.clear()
    }
    ratingRequestArray.forEach(ratingRequest => {
      self.ratingRequests.set(String(ratingRequest.id), ratingRequest)
    })
  }),

  getLastRatings: flow(function* () {
    const data = (yield self.request('get', `client/rating/latest`)) as LastRatingSnapshot[]
    if (self.lastRatings.size > 0) {
      self.lastRatings.clear()
    }
    data.forEach(rating => {
      self.lastRatings.set(String(rating.id), rating)
    })
  }),

  getRatingRequestDetails: flow(function* (id: number) {
    return (yield self.request('get', `client/rating/${id}`)) as RatingRequestDetails
  }),

  submitRating: flow(function* (params: RatingForm) {
    const formData = new FormData()
    formData.append('job_id', String(params.job_id))
    formData.append('job_rating', String(params.job_rating))
    params.ogrammerRates.forEach((ogRate, index) => {
      formData.append(`sps[${index}][sp_id]`, String(ogRate.sp_id))
      formData.append(`sps[${index}][rating]`, String(ogRate.rating))
      formData.append(`sps[${index}][comment]`, ogRate.comment ?? '')
      let criteriaIndex = 0
      ogRate.criterias.forEach(criteria => {
        formData.append(`sps[${index}][criterias][${criteriaIndex}]`, String(criteria))
        criteriaIndex++
      })
    })
    yield self.request('post', 'client/rating', formData)
  }),
})

export const ratingViews = (self: DataStore) => ({
  get ratingRequestList() {
    return Array.from(self.ratingRequests.values())
  },

  get groupedLastRatings() {
    const result: {
      location_id: number
      designation_id: number
      location_name: string
      designation_name: string
      ratings: LastRatingSnapshot[]
    }[] = []
    const lastRatingsArray = Array.from(self.lastRatings.values())
    lastRatingsArray.forEach(rating => {
      const existingGroup = result.find(
        group => group.location_id === rating.location_id && group.designation_id === rating.designation_id,
      )
      if (existingGroup) {
        existingGroup.ratings.push(rating)
      } else {
        result.push({
          location_id: rating.location_id,
          designation_id: rating.designation_id,
          location_name: rating.location_name,
          designation_name: rating.designation_name,
          ratings: [rating],
        })
      }
    })

    return result
  },

  get ratingRequestsCount() {
    return self.ratingRequests.size
  },
})

export type RatingForm = {
  job_id: number
  job_rating: number
  ogrammerRates: {
    sp_id: number
    rating: number
    criterias: Set<number>
    comment: string | null
  }[]
}

export type RatingRequest = SnapshotIn<typeof RatingRequestModel>
export type RatingRequestSnapshot = SnapshotOut<typeof RatingRequestModel>
export type LastRatingSnapshot = SnapshotOut<typeof LastRatingModel>
