import { useState } from 'react'
import { observer } from 'mobx-react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { useStore, useQuery } from '../../../../../utils/mst-hooks'
import { WorkRequirement } from '../../../../../models/work-requirement'
import { Checkbox } from '../../../../checkbox/checkbox'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { SearchInput } from '../../../../search-input/search-input'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import './requirements.scss'
import { Label } from '../../../../label/label'

export const WorkRequirementsComponent = observer(({ job }: { job: DraftJobInstance }) => {
  const { getWorkRequirements, getWorkCategoryRequirements } = useStore().data
  const [filteredRequirements, setFilteredRequirements] = useState<WorkRequirement[]>([])
  const [calculatingList, setCalculatingList] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const requirements = getWorkCategoryRequirements(job.category.id as number) ?? []

  const { isLoading: requirementsLoading } = useQuery(() => getWorkRequirements(job.category.id as number), {
    onSuccess: (workRequirements: WorkRequirement[]) => {
      workRequirements
        .filter(requirement => {
          if (requirement.is_default) {
            job.requirements.add(requirement.id)
          }
          return requirement.is_default
        })
        .map(requirement => requirement.id)
      setFilteredRequirements(workRequirements)
    },
  })

  const filterRequirements = useDebouncedCallback((value: string) => {
    setFilteredRequirements(requirements.filter(item => item.name.toLowerCase().includes(value.toLowerCase())))
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterRequirements(value)
  }

  return (
    <div className="JobDraftRequirements">
      <span className="Popup__title">
        <Label title={t('common.optional')} /> {t('job_draft_details.requirements_title')}
      </span>
      <span className="Popup__subtitle">{t('job_draft_details.requirements_subtitle')}</span>
      {requirementsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput
            value={searchValue}
            handleValueChange={handleSearchChange}
            className="JobDraftRequirements__searchContainer"
          />
          <Separator.Vertical height={18} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div className="JobDraftRequirements__list">
              {filteredRequirements.map(requirement => {
                const selected = job.requirements.has(requirement.id)
                return (
                  <div
                    onClick={() => {
                      if (selected) {
                        job.requirements.remove(requirement.id)
                      } else {
                        job.requirements.add(requirement.id)
                      }
                    }}
                    key={requirement.id}
                    className="JobDraftRequirements__list__item"
                  >
                    <span className="JobDraftRequirements__list__item__label">{requirement.name}</span>
                    <Checkbox isActive={selected} type="circle" />
                  </div>
                )
              })}
            </div>
          )}
        </>
      )}
      <Separator.Vertical height={24} />
    </div>
  )
})
// @ts-ignore
WorkRequirementsComponent.displayName = 'WorkRequirementsComponent'

export const WorkRequirements = WorkRequirementsComponent
