import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'

import { useDelayedQuery, useQuery, useStore } from '../../utils/mst-hooks'
import { useIsPaymentsEnabled } from '../../services/payment-service'
import { UIStateContext } from '../ui-state-management/ui-state-management'
import { AmountLeftToPayPanel } from '../amount-left-to-pay-panel/amount-left-to-pay-panel'
import { OverdueSnapshot } from '../../models/payment'
import { PendingOrdersPanel } from '../pending-orders-panel/pending-orders-panel'
import { AttendanceReviewPanel } from '../attendance-review-panel/attendance-review-panel'
import { withErrorBoundary } from '../error/with-error-boundary'
import { UserRole } from '../../models/account'

const userRolesToShowPaymentPanel = [Number(UserRole.Owner), Number(UserRole.Admin), Number(UserRole.Finance)]

export const BottomPanel = withErrorBoundary(
  observer(() => {
    const { setShowHelpButton } = useContext(UIStateContext)

    const {
      getProfile,
      profile,
      overdue,
      getOverdue,
      logged,
      statistics,
      getStatistics,
      fetchAttendanceReportJob,
      attendanceReportJobView,
    } = useStore().data

    useQuery(getProfile)
    useQuery(getStatistics)
    useQuery(fetchAttendanceReportJob)

    const { enableQuery: getOverdueQuery } = useDelayedQuery(getOverdue)

    useEffect(() => {
      if (Boolean(profile)) {
        const isAllowedToSeePaymentPanel = profile?.roles.reduce((allowed, role) => {
          return userRolesToShowPaymentPanel.includes(role) ? true : allowed
        }, false)

        if (isAllowedToSeePaymentPanel) {
          getOverdueQuery()
        }
      }
    }, [profile])

    const isPaymentsEnabled = useIsPaymentsEnabled()
    const showPaymentPanel = logged && isPaymentsEnabled() && overdue && overdue.pending_payments_count

    const ordersNeedConfirmCount = Number(statistics?.orders_need_confirm_count)
    const showOrderConfirmPanel = logged && ordersNeedConfirmCount > 0

    const shifts = attendanceReportJobView()
    const showAttendanceReviewPanel = logged && shifts.length > 0

    useEffect(() => {
      setShowHelpButton(!showPaymentPanel && !showOrderConfirmPanel && !showAttendanceReviewPanel)
    }, [showPaymentPanel, showOrderConfirmPanel, showAttendanceReviewPanel])

    if (showPaymentPanel) {
      return <AmountLeftToPayPanel overdue={overdue as OverdueSnapshot} />
    }

    if (showOrderConfirmPanel) {
      return <PendingOrdersPanel ordersNeedConfirmCount={ordersNeedConfirmCount} />
    }

    if (showAttendanceReviewPanel) {
      return <AttendanceReviewPanel shifts={shifts} />
    }

    return null
  }),
)
