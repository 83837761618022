import { types, cast, addMiddleware } from 'mobx-state-tree'

import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const NationalitiesModel = types
  .model('Nationalities', {
    use_nationalities: types.optional(types.boolean, false),
    nationalities_list: types.maybeNull(types.array(types.number)),
    ethnicities_list: types.maybeNull(types.array(types.number)),
    allow_any_nationality: types.optional(types.boolean, true),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },

    hasNationality(id: number) {
      return Boolean(self.nationalities_list?.includes(id))
    },

    hasEthnicity(id: number) {
      return Boolean(self.ethnicities_list?.includes(id))
    },
  }))
  .actions(self => ({
    clear() {
      self.nationalities_list?.clear()
      self.ethnicities_list?.clear()
    },
  }))
  .actions(self => ({
    addNationality(id: number) {
      if (self.nationalities_list) {
        self.nationalities_list.push(id)
      } else {
        self.nationalities_list = cast([id])
      }
      self.error = null
    },

    addEthnicity(id: number) {
      if (self.ethnicities_list) {
        self.ethnicities_list.push(id)
      } else {
        self.ethnicities_list = cast([id])
      }
      self.error = null
    },

    removeNationality(id: number) {
      self.nationalities_list = cast(self.nationalities_list?.filter(item => item !== id))
    },

    removeEthnicity(id: number) {
      self.ethnicities_list = cast(self.ethnicities_list?.filter(item => item !== id))
    },

    setNationalities(nationalities: number[]) {
      self.nationalities_list = cast(nationalities)
      self.error = null
    },

    setEthnicities(ethnicities: number[]) {
      self.ethnicities_list = cast(ethnicities)
      self.error = null
    },

    useNationalities(value: boolean) {
      self.use_nationalities = value
      if (!value) {
        self.clear()
      }
      self.error = null
    },

    setAllowAnyNationality(value: boolean) {
      self.allow_any_nationality = value
    },

    validate() {
      return true
    },
  }))

export function createNationalitiesMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.nationalities) {
      return abort(targetInstance.nationalities.validate())
    }
    return next(call)
  })
}
