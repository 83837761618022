import { DraftJobInstance } from '../../../models-ui/order/store/draft-job'
import { t } from '../../../translations'
import { DraftJobFields } from '../../../models-ui/order/store/job-fields/fields'

export const getOptionalFieldsLabels = (job: DraftJobInstance): string => {
  const additionalPreferences = []
  if (job.languages.use_languages) {
    additionalPreferences.push(t('job_draft_details.languages'))
  }
  if (job.gender.use_gender) {
    additionalPreferences.push(t('job_draft_details.gender'))
  }
  if (job.documents.use_documents) {
    additionalPreferences.push(t('job_draft_details.additional_documents'))
  }
  if (job.manualSelection.use_interview) {
    additionalPreferences.push(t('job_draft_details.video_interview'))
  }
  if (job.accreditation.use_accreditation === true) {
    additionalPreferences.push(t('job_draft_details.accreditation'))
  }
  if (job.nationalities.use_nationalities === true && job.fields.hasField(DraftJobFields.nationalities)) {
    additionalPreferences.push(t('job_draft_details.nationalities'))
  }
  return additionalPreferences.join(', ')
}
